import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Helpers } from '@app/helpers/helpers.class';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { ReportService } from '@app/services/report.service';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { IPunchSearch } from '@app/interfaces/punch-search.interface';
import { IReport } from '@app/interfaces/report.interface';
import {StaffingCompanyService} from "@app/services/StaffingCompany.service";
import {IStaffingCompany} from "@app/interfaces/StaffingCompany.interface";
import {IStaffingCompanyEmployeesTimeSearch} from "@app/interfaces/staffing-company-employees-time.interface";
import {saveAs} from "file-saver-es";

@Component({
    selector: 'app-report-staffing-company-time',
    templateUrl: './report-staffing-company-time.partial.html',
    styleUrls: ['./report-staffing-company-time.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportStaffingCompanyTimePartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('staffingCompanyIds') public inputStaffingCompany: IStaffingCompany;
    @Input('punchDateFrom') public inputPunchDateFrom: string;
    @Input('punchDateTo') public inputPunchDateTo: string;

    public report: IReport;
    public loading: boolean = false;
    public filterStaffingCompany: IStaffingCompany;
    public filterPunchDateFrom: string;
    public filterPunchDateTo: string;
    public staffingCompanies: IStaffingCompany[];
    displayedColumns: string[] = ['Name', 'name', 'weight', 'symbol'];

    constructor(
        private staffingCompanyService: StaffingCompanyService,
        private reportService: ReportService
    ) {}

    public ngOnInit(): void {
        this.filterStaffingCompany = null;
        this.filterPunchDateFrom = Helpers.toShortDateString(new Date());
        this.filterPunchDateTo = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputStaffingCompany)) {
            this.filterStaffingCompany = this.inputStaffingCompany;
        }

        if (Helpers.isDefined(this.inputPunchDateFrom)) {
            this.filterPunchDateFrom = this.inputPunchDateFrom;
        }

        if (Helpers.isDefined(this.inputPunchDateTo)) {
            this.filterPunchDateTo = this.inputPunchDateTo;
        }
        this.getStaffingCompanies();
    }

    public getReport(): void {
        this.report = null;
        let search: IStaffingCompanyEmployeesTimeSearch = {
            staffingCompanyId: this.filterStaffingCompany.staffingCompanyId,
            staffingCompanyName: this.filterStaffingCompany.companyName,
            punchDatefrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo)
        };

        this.loading = true;
        this.reportService.getStaffingCompanyTimeReport(search).subscribe({


            next: (data) => {
                console.log(data)
                const contentDisposition = data.headers.get('Content-Disposition');
                let filename = 'downloaded_file.xlsx'; // Default filename

                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                    if (filenameMatch.length > 1) {
                        filename = filenameMatch[1];
                    }
                }

                saveAs(data.body, filename)
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get staffing company time report');
            },
        });

        let filterSettings = {
            staffingCompany: this.filterStaffingCompany,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
        };

        this.filterChanged.emit(filterSettings);
    }

    public getStaffingCompanies() {
        this.staffingCompanyService.getAll().subscribe({
            next: (data) => {
                this.staffingCompanies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get staffing companies');
            },
        });
    }
}
