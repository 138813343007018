import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Helpers } from '@app/helpers/helpers.class';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { ReportService } from '@app/services/report.service';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';
import { IPunchSearch } from '@app/interfaces/punch-search.interface';
import { IReport } from '@app/interfaces/report.interface';

@Component({
    selector: 'app-report-time-per-cost-pool',
    templateUrl: './report-time-per-cost-pool.partial.html',
    styleUrls: ['./report-time-per-cost-pool.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportTimePerCostPoolPartial implements OnInit {
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('companyIds') public inputCompanyIds: string;
    @Input('employmentTypeIds') public inputEmploymentTypeIds: string;
    @Input('departmentIds') public inputDepartmentIds: string;
    @Input('punchDateFrom') public inputPunchDateFrom: string;
    @Input('punchDateTo') public inputPunchDateTo: string;

    public report: IReport;
    public loading: boolean = false;
    public filterCompanyIds: number[];
    public filterEmploymentTypeIds: number[];
    public filterDepartmentIds: number[];
    public filterPunchDateFrom: string;
    public filterPunchDateTo: string;
    public companies: ICompany[];
    public employmentTypes: IEmploymentType[];
    public companyDepartments: ICompanyDepartment[];
    public costPoolGroupColumns: string[] = [
        'code',
        'scheduledTime',
        'overtime',
        'extraWorkTime',
        'unsocialHoursPayment',
        'absence',
        'total',
        'expand',
    ];
    public expandedCostPoolGroup: any;
    public expandedCostPool: any;

    constructor(
        private companyService: CompanyService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService,
        private reportService: ReportService
    ) {}

    public ngOnInit(): void {
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterPunchDateFrom = Helpers.toShortDateString(new Date());
        this.filterPunchDateTo = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputPunchDateFrom)) {
            this.filterPunchDateFrom = this.inputPunchDateFrom;
        }

        if (Helpers.isDefined(this.inputPunchDateTo)) {
            this.filterPunchDateTo = this.inputPunchDateTo;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getReport();
    }

    public getReport(): void {
        this.report = null;
        let search: IPunchSearch = {
            query: '',
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
            page: 1,
        };

        this.loading = true;
        this.reportService.getTimePerCostPoolReport(search).subscribe({
            next: (data) => {
                for (let i = 0; i < data.items.length; i++) {
                    data.items[i].index = i;
                    data.items[i].costPoolColumns = this.getCostPoolColumns(i);
                    for (let x = 0; x < data.items[i].costPools.length; x++) {
                        data.items[i].costPools[x].index = x;
                        data.items[i].costPools[x].employeeColumns = this.getEmployeeColumns(i, x);
                    }
                }
                this.report = data;
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get time per cost pool report');
            },
        });

        let filterSettings = {
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
        };

        this.filterChanged.emit(filterSettings);
    }

    public getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    public getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    public getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    public toggleCostPoolGroupRow(costPoolGroup: any): void {
        if (this.expandedCostPoolGroup == costPoolGroup) {
            this.expandedCostPoolGroup = null;
        } else {
            this.expandedCostPoolGroup = costPoolGroup;
        }
    }

    public toggleCostPoolRow(costPool: any): void {
        if (this.expandedCostPool == costPool) {
            this.expandedCostPool = null;
        } else {
            this.expandedCostPool = costPool;
        }
    }

    public getCostPoolColumns(i: number): any[] {
        return [
            'name_' + i,
            'scheduledTime_' + i,
            'overtime_' + i,
            'extraWorkTime_' + i,
            'unsocialHoursPayment_' + i,
            'absence_' + i,
            'total_' + i,
            'expand_' + i,
        ];
    }

    public getEmployeeColumns(i: number, x: number): any[] {
        return [
            'name_' + i + '_' + x,
            'totalTime_' + i + '_' + x,
            'totalOvertime_' + i + '_' + x,
            'totalUnsocialHours_' + i + '_' + x,
            'totalAbsence_' + i + '_' + x,
            'scheduledTime_' + i + '_' + x,
        ];
    }
}
