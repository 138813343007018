<div class="container-fluid">
    <div class="row filters">
        <div class="col company-filter">
            <label>Bolag</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterCompanyIds" placeholder="Välj bolag" multiple>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId"
                        >{{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col employment-type-filter">
            <label>Anställnig</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterEmploymentTypeIds" placeholder="Välj anställningstyp" multiple>
                    <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType.employmentTypeId">
                        {{employmentType.employmentTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row filters">
        <div class="col company-department-filter">
            <label>Avdelning</label>
            <mat-form-field>
                <mat-select [(ngModel)]="filterDepartmentIds" placeholder="Välj avdelning" multiple>
                    <mat-option *ngFor="let department of companyDepartments" [value]="department.departmentId">
                        {{companies | valueFromObjectArray:'companyId':department.companyId:'companyShort'}} -
                        {{department.departmentName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col punch-date-filter">
            <label>Stämpeldatum</label>
            <div class="date-range">
                <mat-form-field class="from" (click)="pickerFrom.open()">
                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filterPunchDateFrom" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <span>-</span>
                <mat-form-field class="to" (click)="pickerTo.open()">
                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filterPunchDateTo" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col button-filter">
            <button mat-raised-button (click)="getReport()" color="primary">Visa rapport</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="divider-wrapper">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="report && report.items.length > 0; else noResults;">
            <div class="table-wrapper">
                <table mat-table [dataSource]="report.items" multiTemplateDataRows>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef class="group-code">Kod</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-code">
                            {{costPoolGroup.code === 0 ? '-' : costPoolGroup.code}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-scheduled-time"></td>
                    </ng-container>
                    <ng-container matColumnDef="scheduledTime">
                        <th mat-header-cell *matHeaderCellDef class="group-scheduled-time">Schemalagd tid</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-scheduled-time">
                            {{costPoolGroup.scheduledMinutes === 0 ? '-' : (costPoolGroup.scheduledMinutes | readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-scheduled-time">
                            {{report.metaData.totalScheduledMinutes === 0 ? '-' : (report.metaData.totalScheduledMinutes |
                            readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="overtime">
                        <th mat-header-cell *matHeaderCellDef class="group-overtime">Övertid</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-overtime">
                            {{costPoolGroup.overtimeMinutes === 0 ? '-' : (costPoolGroup.overtimeMinutes | readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-overtime">
                            {{report.metaData.totalOvertimeMinutes === 0 ? '-' : (report.metaData.totalOvertimeMinutes |
                            readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="extraWorkTime">
                        <th mat-header-cell *matHeaderCellDef class="group-extra-work-time">Mertid</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-extra-work-time">
                            {{costPoolGroup.extraWorkTimeMinutes === 0 ? '-' : (costPoolGroup.extraWorkTimeMinutes |
                            readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-extra-work-time">
                            {{report.metaData.totalExtraWorkTimeMinutes === 0 ? '-' : (report.metaData.totalExtraWorkTimeMinutes |
                            readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="unsocialHoursPayment">
                        <th mat-header-cell *matHeaderCellDef class="group-unsocial-hours-payment">OB</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-unsocial-hours-payment">
                            {{costPoolGroup.unsocialHoursPaymentMinutes === 0 ? '-' : (costPoolGroup.unsocialHoursPaymentMinutes |
                            readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-unsocial-hours-payment">
                            {{report.metaData.totalUnsocialHoursPaymentMinutes === 0 ? '-' :
                            (report.metaData.totalUnsocialHoursPaymentMinutes | readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="absence">
                        <th mat-header-cell *matHeaderCellDef class="group-absence">Frånvaro</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-absence">
                            {{costPoolGroup.absenceMinutes === 0 ? '-' : (costPoolGroup.absenceMinutes | readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-absence">
                            {{report.metaData.totalAbsenceMinutes === 0 ? '-' : (report.metaData.totalAbsenceMinutes |
                            readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef class="group-total">Totalt</th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="group-total">
                            {{costPoolGroup.totalMinutes === 0 ? '-' : (costPoolGroup.totalMinutes | readableTimeSpan)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="group-total">
                            {{report.metaData.totalMinutes === 0 ? '-' : (report.metaData.totalMinutes | readableTimeSpan)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                        <td mat-cell *matCellDef="let costPoolGroup" class="expand">
                            <mat-icon *ngIf="costPoolGroup != expandedCostPoolGroup">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="costPoolGroup == expandedCostPoolGroup">keyboard_arrow_up</mat-icon>
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="expand"></td>
                    </ng-container>
                    <ng-container matColumnDef="costPools">
                        <td mat-cell *matCellDef="let costPoolGroup" [attr.colspan]="costPoolGroupColumns.length">
                            <div
                                class="cost-pools-wrapper"
                                [@toggle]="costPoolGroup == expandedCostPoolGroup ? 'expanded' : 'collapsed'"
                                *ngIf="costPoolGroup.costPools.length > 0">
                                <table class="cost-pools" mat-table [dataSource]="costPoolGroup.costPools" multiTemplateDataRows>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[0]">
                                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                                        <td mat-cell *matCellDef="let costPool" class="name">{{costPool.name}}</td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[1]">
                                        <th mat-header-cell *matHeaderCellDef class="scheduled-time">Schemalagd tid</th>
                                        <td mat-cell *matCellDef="let costPool" class="scheduled-time">
                                            {{costPool.scheduledMinutes === 0 ? '-' : (costPool.scheduledMinutes |
                                            readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[2]">
                                        <th mat-header-cell *matHeaderCellDef class="overtime">Övertid</th>
                                        <td mat-cell *matCellDef="let costPool" class="overtime">
                                            {{costPool.overtimeMinutes === 0 ? '-' : (costPool.overtimeMinutes |
                                            readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[3]">
                                        <th mat-header-cell *matHeaderCellDef class="extra-work-time">Mertid</th>
                                        <td mat-cell *matCellDef="let costPool" class="extra-work-time">
                                            {{costPool.extraWorkTimeMinutes === 0 ? '-' : (costPool.extraWorkTimeMinutes |
                                            readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[4]">
                                        <th mat-header-cell *matHeaderCellDef class="unsocial-hours-payment">OB</th>
                                        <td mat-cell *matCellDef="let costPool" class="unsocial-hours-payment">
                                            {{costPool.unsocialHoursPaymentMinutes === 0 ? '-' :
                                            (costPool.unsocialHoursPaymentMinutes | readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[5]">
                                        <th mat-header-cell *matHeaderCellDef class="absence">Frånvaro</th>
                                        <td mat-cell *matCellDef="let costPool" class="absence">
                                            {{costPool.absenceMinutes === 0 ? '-' : (costPool.absenceMinutes | readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[6]">
                                        <th mat-header-cell *matHeaderCellDef class="total">Totalt</th>
                                        <td mat-cell *matCellDef="let costPool" class="total">
                                            {{costPool.totalMinutes === 0 ? '-' : (costPool.totalMinutes | readableTimeSpan)}}
                                        </td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="costPoolGroup.costPoolColumns[7]">
                                        <th mat-header-cell *matHeaderCellDef class="expand"></th>
                                        <td mat-cell *matCellDef="let costPool" class="expand">
                                            <mat-icon *ngIf="costPool != expandedCostPool">keyboard_arrow_down </mat-icon>
                                            <mat-icon *ngIf="costPool == expandedCostPool">keyboard_arrow_up</mat-icon>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef class="expand"></td>
                                    </ng-container>
                                    <ng-container [matColumnDef]="'employees_' + costPoolGroup.index">
                                        <td
                                            mat-cell
                                            *matCellDef="let costPool"
                                            [attr.colspan]="costPoolGroup.costPoolColumns.length">
                                            <div
                                                class="employees-wrapper"
                                                [@toggle]="costPool == expandedCostPool ? 'expanded' : 'collapsed'"
                                                *ngIf="costPool.employees.length > 0">
                                                <table class="employees" mat-table [dataSource]="costPool.employees">
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[0]">
                                                        <th mat-header-cell *matHeaderCellDef class="name">Namn</th>
                                                        <td mat-cell *matCellDef="let employee" class="name">
                                                            {{employee.lastName}}, {{employee.firstName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[1]">
                                                        <th mat-header-cell *matHeaderCellDef class="total-time">Arbetad tid</th>
                                                        <td mat-cell *matCellDef="let employee" class="total-time">
                                                            {{employee.totalMinutes | readableTimeSpan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[2]">
                                                        <th mat-header-cell *matHeaderCellDef class="total-over-time">
                                                            Varav Ö/M.t.
                                                        </th>
                                                        <td mat-cell *matCellDef="let employee" class="total-over-time">
                                                            {{ employee.totalOvertimeMinutes === 0 &&
                                                            employee.totalExtraWorkMinutes === 0 ? '-' :
                                                            (employee.totalOvertimeMinutes + employee.totalExtraWorkMinutes |
                                                            readableTimeSpan) }}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[3]">
                                                        <th mat-header-cell *matHeaderCellDef class="total-unsocial-hours">
                                                            Varav OB
                                                        </th>
                                                        <td mat-cell *matCellDef="let employee" class="total-unsocial-hours">
                                                            {{employee.totalUnsocialHoursMinutes === 0 ? '-' :
                                                            (employee.totalUnsocialHoursMinutes | readableTimeSpan)}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[4]">
                                                        <th mat-header-cell *matHeaderCellDef class="total-absence">Frånvaro</th>
                                                        <td mat-cell *matCellDef="let employee" class="total-absence">
                                                            <span
                                                                *ngIf="employee.totalAbsenceMinutes != employee.validAbsenceMinutes; else noDiffAbsence"
                                                                matTooltip="Giltig frånvaro: {{employee.validAbsenceMinutes | readableTimeSpan}}">
                                                                {{employee.totalAbsenceMinutes | readableTimeSpan}}
                                                            </span>
                                                            <ng-template #noDiffAbsence>
                                                                {{employee.totalAbsenceMinutes === 0 ? '-' :
                                                                (employee.totalAbsenceMinutes | readableTimeSpan)}}
                                                            </ng-template>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container [matColumnDef]="costPool.employeeColumns[5]">
                                                        <th mat-header-cell *matHeaderCellDef class="scheduled-time">
                                                            Schemalagt
                                                        </th>
                                                        <td mat-cell *matCellDef="let employee" class="scheduled-time">
                                                            {{employee.scheduledMinutes === 0 ? '-' : (employee.scheduledMinutes |
                                                            readableTimeSpan)}}
                                                            <mat-icon
                                                                *ngIf="employee.hasActiveSchedule"
                                                                class="has-active-schedule"
                                                                click-stop-propagation
                                                                >calendar_today</mat-icon
                                                            >
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="costPool.employeeColumns"></tr>
                                                    <tr
                                                        mat-row
                                                        *matRowDef="let employee; columns: costPool.employeeColumns;"></tr>
                                                </table>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="costPoolGroup.costPoolColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let costPool; columns: costPoolGroup.costPoolColumns;"
                                        class="cost-pool-group-row"
                                        (click)="toggleCostPoolRow(costPool)"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let costPool; columns: ['employees_' + costPoolGroup.index];"
                                        class="employees-row"></tr>
                                </table>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="costPoolGroupColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let costPoolGroup; columns: costPoolGroupColumns;"
                        class="cost-pool-group-row"
                        (click)="toggleCostPoolGroupRow(costPoolGroup)"></tr>
                    <tr mat-row *matRowDef="let costPoolGroup; columns: ['costPools']" class="cost-pools-row"></tr>
                    <tr mat-footer-row *matFooterRowDef="costPoolGroupColumns"></tr>
                </table>
            </div>
        </div>
        <ng-template #noResults>
            <div class="col">
                <p class="no-results">Inga kostnadsställen att visa</p>
            </div>
        </ng-template>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</div>
