<app-admin-menu></app-admin-menu>

<h1 class="mat-headline-3">Tid per kostnadsställe</h1>

<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="compact">
                <mat-card-content>
                    <app-report-time-per-cost-pool (filterChanged)="updateQueryParameters($event)"
                        [companyIds]="companyIds" [employmentTypeIds]="employmentTypeIds"
                        [departmentIds]="departmentIds" [punchDateFrom]="punchDateFrom"
                        [punchDateTo]="punchDateTo">
                    </app-report-time-per-cost-pool>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<app-messages #messagesPartial [employeeId]="employeeId"></app-messages>

<app-admin-footer></app-admin-footer>
