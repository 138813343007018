import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver-es';
import { Helpers } from '@app/helpers/helpers.class';
import { remove, find } from 'lodash-es';
import { PunchService } from '@app/services/punch.service';
import { CompanyService } from '@app/services/company.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { CostPoolService } from '@app/services/cost-pool.service';
import { CompanyDepartmentService } from '@app/services/company-department.service';
import { PunchWarningDialog } from '@app/shared/dialogs/punch-warning/punch-warning.dialog';
import { AlertDialog } from '@app/shared/dialogs/alert/alert.dialog';
import { EmployeeScheduleDialog } from '@app/shared/dialogs/employee-schedule/employee-schedule.dialog';
import { WorkTimePerCostPoolDialog } from '@app/shared/dialogs/work-time-per-cost-pool/work-time-per-cost-pool.dialog';
import { IPage } from '@app/interfaces/page.interface';
import { ICompany } from '@app/interfaces/company.interface';
import { IEmploymentType } from '@app/interfaces/employment-type.interface';
import { IEmployeePunchList } from '@app/interfaces/employee-punch-list.interface';
import { IPunchSearch } from '@app/interfaces/punch-search.interface';
import { IPunchWarning } from '@app/interfaces/punch-warning.interface';
import { ICostPool } from '@app/interfaces/cost-pool.interface';
import { ICompanyDepartment } from '@app/interfaces/company-department.interface';

@Component({
    selector: 'app-punches-list',
    templateUrl: './punches-list.partial.html',
    styleUrls: ['./punches-list.partial.scss'],
    animations: [
        trigger('toggle', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class PunchesListPartial implements OnInit {
    @Output() filterChanged: EventEmitter<any> = new EventEmitter();
    @Input('query') inputQuery: string;
    @Input('companyIds') inputCompanyIds: string;
    @Input('employmentTypeIds') inputEmploymentTypeIds: string;
    @Input('departmentIds') inputDepartmentIds: string;
    @Input('punchDateFrom') inputPunchDateFrom: string;
    @Input('punchDateTo') inputPunchDateTo: string;
    @Input('page') inputPage: number;

    punchesPage: IPage<any>;
    pageIndex: number;
    loading: boolean = false;
    filterQuery: string;
    filterCompanyIds: number[];
    filterEmploymentTypeIds: number[];
    filterDepartmentIds: number[];
    filterPunchDateFrom: string;
    filterPunchDateTo: string;
    companies: ICompany[];
    employmentTypes: IEmploymentType[];
    companyDepartments: ICompanyDepartment[];
    expandedEmployee: any;
    expandedPunchDay: any;
    employeeColumns: string[] = [
        'name',
        'totalTime',
        'totalOvertime',
        'totalUnsocialHours',
        'totalAbsence',
        'scheduledTime',
        'statusIcons',
        'expand',
    ];
    dataSource: MatTableDataSource<IEmployeePunchList>;
    visibleEmployeeIds: number[] = [];

    constructor(
        private dialog: MatDialog,
        private punchService: PunchService,
        private companyService: CompanyService,
        private costPoolService: CostPoolService,
        private employmentTypeService: EmploymentTypeService,
        private companyDepartmentService: CompanyDepartmentService
    ) {}

    ngOnInit(): void {
        this.filterQuery = '';
        this.filterCompanyIds = [];
        this.filterEmploymentTypeIds = [];
        this.filterDepartmentIds = [];
        this.filterPunchDateFrom = Helpers.toShortDateString(new Date());
        this.filterPunchDateTo = Helpers.toShortDateString(new Date());

        if (Helpers.isDefined(this.inputQuery)) {
            this.filterQuery = this.inputQuery;
        }

        if (Helpers.isDefined(this.inputCompanyIds)) {
            this.filterCompanyIds = this.inputCompanyIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputEmploymentTypeIds)) {
            this.filterEmploymentTypeIds = this.inputEmploymentTypeIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputDepartmentIds)) {
            this.filterDepartmentIds = this.inputDepartmentIds.split(',').map(function (item) {
                return parseInt(item, 10);
            });
        }

        if (Helpers.isDefined(this.inputPunchDateFrom)) {
            this.filterPunchDateFrom = this.inputPunchDateFrom;
        }

        if (Helpers.isDefined(this.inputPunchDateTo)) {
            this.filterPunchDateTo = this.inputPunchDateTo;
        }

        if (Helpers.isDefined(this.inputPage)) {
            this.pageIndex = this.inputPage - 1;
        }

        this.getCompanies();
        this.getEmploymentTypes();
        this.getCompanyDepartments();
        this.getPunchesPage(1);
    }

    getPunchesPage(page: number): void {
        this.punchesPage = null;
        let search: IPunchSearch = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
            page: page,
        };

        this.loading = true;
        this.punchService.getPunches(search).subscribe({
            next: (data) => {
                for (let i = 0; i < data.items.length; i++) {
                    data.items[i].index = i;
                    data.items[i].punchDayColumns = this.getPunchDayColumns(i);
                    for (let x = 0; x < data.items[i].days.length; x++) {
                        data.items[i].days[x].index = x;
                        data.items[i].days[x].punchColumns = this.getPunchColumns(i, x);
                    }
                }
                this.punchesPage = data;
                this.dataSource = new MatTableDataSource<IEmployeePunchList>(this.punchesPage.items);
                this.loading = false;
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Helpers.captureSentryError('Could not get punches page');
            },
        });

        let filterSettings = {
            query: this.filterQuery,
            companyIds: this.filterCompanyIds,
            employmentTypeIds: this.filterEmploymentTypeIds,
            departmentIds: this.filterDepartmentIds,
            punchDateFrom: Helpers.toShortDateString(this.filterPunchDateFrom),
            punchDateTo: Helpers.toShortDateString(this.filterPunchDateTo),
        };

        this.filterChanged.emit(filterSettings);
    }

    pageChanged(event: any): void {
        this.getPunchesPage(event.pageIndex + 1);
        this.pageIndex = event.pageIndex;
    }

    getCompanies() {
        this.companyService.getAll().subscribe({
            next: (data) => {
                this.companies = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get companies');
            },
        });
    }

    getEmploymentTypes() {
        this.employmentTypeService.getAll().subscribe({
            next: (data) => {
                this.employmentTypes = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get employment types');
            },
        });
    }

    getCompanyDepartments() {
        this.companyDepartmentService.getAll().subscribe({
            next: (data) => {
                this.companyDepartments = data;
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get copmany departments');
            },
        });
    }

    togglePunchDayRow(employee: any): void {
        if (this.expandedEmployee == employee) {
            this.expandedEmployee = null;
            this.expandedPunchDay = null;
            setTimeout(
                (e) => {
                    remove(this.visibleEmployeeIds, (employeeId) => {
                        return employeeId === e.employeeId;
                    });
                },
                1,
                employee
            );
        } else {
            this.visibleEmployeeIds.push(employee.employeeId);
            setTimeout(
                (e) => {
                    this.expandedEmployee = e;
                },
                1,
                employee
            );
        }
    }

    showWarnings(employee: IEmployeePunchList, day: string = null): void {
        let warnings: IPunchWarning[] = [];
        for (let i = 0; i < employee.days.length; i++) {
            if (day === null || Helpers.toShortDateString(day) === Helpers.toShortDateString(employee.days[i].day)) {
                warnings = warnings.concat(employee.days[i].warnings);
            }
        }

        this.dialog.open(PunchWarningDialog, {
            width: '650px',
            data: {
                warnings: warnings,
            },
        });
    }

    showEmployeeSchedule(employeeId: number, atDate: string): void {
        this.dialog.open(EmployeeScheduleDialog, {
            width: '500px',
            data: {
                employeeId: employeeId,
                atDate: atDate,
            },
        });
    }

    exportPunchesPreCheck(): void {
        if (!Helpers.isDefined(this.punchesPage) || this.punchesPage.items.length === 0) {
            return;
        }

        if (this.filterCompanyIds.length !== 1) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel',
                    message: 'För att exportera en fil måste endast ett bolag vara valt',
                },
            });
            return;
        }

        let missingExternalEmployeeId: string[] = [];

        for (let employee of this.punchesPage.items) {
            if (!Helpers.isDefined(employee.fortnoxEmployeeId) || employee.fortnoxEmployeeId <= 0) {
                missingExternalEmployeeId.push(employee.lastName + ', ' + employee.firstName);
            }
        }

        if (missingExternalEmployeeId.length > 0) {
            this.dialog.open(AlertDialog, {
                width: '400px',
                data: {
                    title: 'Fel',
                    message: 'Följande anställda saknar anställningsnummer i Kontek',
                    list: missingExternalEmployeeId,
                },
            });
            return;
        }

        let that = this;
        const company: ICompany = find(this.companies, function (c: ICompany) {
            return c.companyId === that.filterCompanyIds[0];
        });

        this.costPoolService.getParents().subscribe({
            next: (data) => {
                this.exportPunches(data, company);
            },
            error: (error) => {
                console.error(error);
                Helpers.captureSentryError('Could not get cost pools');
                return;
            },
        });
    }

    showTimePerCostPool(employee: IEmployeePunchList): void {
        this.dialog.open(WorkTimePerCostPoolDialog, {
            width: '900px',
            data: {
                employee,
            },
        });
    }

    exportPunches(costPools: ICostPool[], company: ICompany): void {
        let xml = '',
            punchesXml = '',
            schedulesXml = '',
            newLine = '\r\n',
            tab = '\t';

        xml = '<?xml version="1.0" encoding="utf-8"?>' + newLine;
        xml +=
            '<paxml xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="http://www.paxml.se/2.0/paxml.xsd">' +
            newLine;
        xml += tab + '<header>' + newLine;
        xml += tab + tab + '<version>2.0</version>' + newLine;
        xml += tab + tab + '<format>LÖNIN</format>' + newLine;
        xml += tab + tab + '<datum>' + Helpers.toDateTimeString(new Date()) + '</datum>' + newLine;
        xml += tab + tab + '<foretagorgnr>' + company.orgNumber + '</foretagorgnr>' + newLine;
        xml += tab + tab + '<foretagnamn>' + company.companyName + '</foretagnamn>' + newLine;
        xml += tab + tab + '<programnamn>Stämpelklockan</programnamn>' + newLine;
        xml += tab + tab + '<programlicens>1234</programlicens>' + newLine;
        xml += tab + '</header>' + newLine;

        xml += tab + '<dimensioner>' + newLine;
        xml += tab + tab + '<dimension dim="1" namn="Kostnadsställe"></dimension>' + newLine;
        xml += tab + tab + '<dimension dim="6" namn="Projekt"></dimension>' + newLine;
        xml += tab + '</dimensioner>' + newLine;
        xml += tab + '<resultatenheter>' + newLine;

        for (let costPool of costPools) {
            xml +=
                tab +
                tab +
                '<resultatenhet dim="1" id="' +
                costPool.code +
                '" namn="' +
                costPool.name +
                '"></resultatenhet>' +
                newLine;

            if (costPool.project > 0) {
                xml +=
                    tab +
                    tab +
                    '<resultatenhet dim="6" id="' +
                    costPool.project +
                    '" namn="' +
                    costPool.name +
                    '"></resultatenhet>' +
                    newLine;
            }
        }

        xml += tab + '</resultatenheter>' + newLine;

        let employees: IEmployeePunchList[] = this.punchesPage.items;

        for (let employee of employees) {
            if (employee.hasActiveSchedule) {
                schedulesXml += tab + tab + '<schema anstid="' + employee.fortnoxEmployeeId + '">' + newLine;
            }

            for (let punchDay of employee.days) {
                for (let workTime of punchDay.workTime) {
                    if (
                        workTime.timeType === 'SCHEDULED' ||
                        (workTime.timeType === 'SCHEDULEDWITHUNSOCIALHOURSPAYMENT' && employee.paymentTypeId === 2)
                    ) {
                        continue;
                    }

                    punchesXml += tab + tab + '<tidtrans anstid="' + employee.fortnoxEmployeeId + '">' + newLine;
                    punchesXml += tab + tab + tab + '<tidkod>' + workTime.code + '</tidkod>' + newLine;
                    punchesXml += tab + tab + tab + '<datum>' + Helpers.toShortDateString(punchDay.day) + '</datum>' + newLine;
                    punchesXml +=
                        tab + tab + tab + '<timmar>' + Helpers.minutesToHours(workTime.totalMinutes) + '</timmar>' + newLine;

                    if (workTime.costPoolId > 0) {
                        punchesXml += tab + tab + tab + '<resenheter>' + newLine;
                        punchesXml +=
                            tab + tab + tab + tab + '<resenhet dim="1" id="' + workTime.costPoolCode + '"></resenhet>' + newLine;

                        if (workTime.costPoolProjectCode > 0) {
                            punchesXml +=
                                tab +
                                tab +
                                tab +
                                tab +
                                '<resenhet dim="6" id="' +
                                workTime.costPoolProjectCode +
                                '"></resenhet>' +
                                newLine;
                        }

                        punchesXml += tab + tab + tab + '</resenheter>' + newLine;
                    }

                    punchesXml += tab + tab + '</tidtrans>' + newLine;
                }

                if (punchDay.hasActiveSchedule && punchDay.scheduledMinutes > 0) {
                    schedulesXml +=
                        tab +
                        tab +
                        tab +
                        '<dag datum="' +
                        Helpers.toShortDateString(punchDay.day) +
                        '" timmar="' +
                        Helpers.minutesToHours(punchDay.scheduledMinutes) +
                        '"></dag>' +
                        newLine;
                }
            }

            if (employee.hasActiveSchedule) {
                schedulesXml += tab + tab + '</schema>' + newLine;
            }
        }

        if (punchesXml.length > 0) {
            xml += tab + '<tidtransaktioner>' + newLine + punchesXml + tab + '</tidtransaktioner>' + newLine;
        }

        if (schedulesXml.length > 0) {
            xml += tab + '<schematransaktioner>' + newLine + schedulesXml + tab + '</schematransaktioner>' + newLine;
        } else {
            xml += tab + '<schematransaktioner></schematransaktioner>' + newLine;
        }

        xml += '</paxml>';

        let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'stamplingar_export_stampelklockan.xml');
    }

    getPunchDayColumns(i: number): any[] {
        return [
            'day_' + i,
            'totalTime_' + i,
            'totalOvertime_' + i,
            'totalUnsocialHours_' + i,
            'totalAbsence_' + i,
            'scheduledTime_' + i,
            'statusIcons_' + i,
            'expand_' + i,
        ];
    }

    getPunchColumns(i: number, x: number): any[] {
        return ['typeIcon_' + i + '_' + x, 'typeName_' + i + '_' + x, 'punchTime_' + i + '_' + x, 'costPool_' + i + '_' + x];
    }
}
