import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";

// Services
import { AuthService } from '@app/services/auth.service';
import { Helpers } from '@app/helpers/helpers.class';

@Component({
    selector: 'app-punches-list-admin-page',
    templateUrl: './punches-list.component.html',
    styleUrls: ['./punches-list.component.scss']
})

export class PunchesListAdminComponent implements OnInit {
    employeeId: number;
    query: string;
    companyIds: number[];
    employmentTypeIds: number[];
    departmentIds: number[];
    punchDateFrom: string;
    punchDateTo: string;
    active: boolean;
    page: number;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.employeeId = this.authService.getEmployeeId();
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

        if (Helpers.isDefined(params['query'])) {
            this.query = params['query'];
        }

        if (Helpers.isDefined(params['companyIds'])) {
            this.companyIds = params['companyIds'];
        }

        if (Helpers.isDefined(params['employmentTypeIds'])) {
            this.employmentTypeIds = params['employmentTypeIds'];
        }

        if (Helpers.isDefined(params['departmentIds'])) {
            this.departmentIds = params['departmentIds'];
        }

        if (Helpers.isDefined(params['punchDateFrom'])) {
            this.punchDateFrom = params['punchDateFrom'];
        }

        if (Helpers.isDefined(params['punchDateTo'])) {
            this.punchDateTo = params['punchDateTo'];
        }

        if (Helpers.isDefined(params['page'])) {
            this.page = params['page'];
        }
    }

    updateQueryParameters(filter: any): void {
        const params: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        params['companyIds'] = filter.companyIds.length > 0 ? filter.companyIds.join(',') : null;
        params['employmentTypeIds'] = filter.employmentTypeIds.length > 0 ? filter.employmentTypeIds.join(',') : null;
        params['departmentIds'] = filter.departmentIds.length > 0 ? filter.departmentIds.join(',') : null;
        params['punchDateFrom'] = filter.punchDateFrom.length > 0 ? filter.punchDateFrom : null;
        params['punchDateTo'] = filter.punchDateTo.length > 0 ? filter.punchDateTo : null;
        params['query'] = Helpers.isDefined(filter.query) && filter.query.length > 0 ?
            filter.query : null;
        params['page'] = filter.page;
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
    }
}
